/** Overrided this file to add quick order link in quick links section (see below) */
import React from "react";
import compose from "recompose/compose";
import { IconButton } from "theme/components/atoms/Button";
import Icon from "theme/components/atoms/Icon";
import Link from "theme/components/atoms/Typography/Link";
import SearchNavigation from "./SearchNavigation";
import CartNavigation from "./CartNavigation";
import AccountNavigation from "./AccountNavigation";
import MenuNavigationMobile from "./Menu/MenuNavigationMobile";
import SubNavigation from "./SubNavigation";
import { useIntl, defineMessages } from "react-intl";
import withIsFeatureActive from "web/core/UNSAFE_featureFlags/withIsFeatureActive";
import Button from "theme/components/atoms/Button";
import IconWithNotification from "theme/components/molecules/IconWithNotification";

const messages = defineMessages({
  search: {
    id: "layouts.Header.Navigation.search",
    defaultMessage: "Search",
  },
  cart: {
    id: "layouts.Header.Navigation.cart",
    defaultMessage: "My Cart",
  },
  cartLength: {
    id: "layouts.Header.Navigation.cartLength",
    defaultMessage: "{quantity, plural, =1 {1 product} other {# products}}",
  },
  menu: {
    id: "layouts.Header.Navigation.menu",
    defaultMessage: "Menu",
  },
  quickOrder: {
    id: "layouts.Header.Navigation.quickOrder",
    defaultMessage: "Quick Order",
  },
});

const Navigation = ({
  searchFeature,
  wishlistFeature,
  data,
  userData,
  selectMenu,
  renderSubNav,
}) => {
  const intl = useIntl();

  const cartLength = userData?.cart?.items_qty ?? null;

  return (
    <nav className="navigation">
      <ul className="navigation__list">
        {!searchFeature.loading && searchFeature.isFeatureActive ? (
          <li className="navigation__item">
            <IconButton
              icon="magnifier"
              title={intl.formatMessage(messages.search)}
              onClick={() => selectMenu("search")}
            />
            {renderSubNav("search")(
              <SubNavigation onClose={() => selectMenu(null)}>
                <SearchNavigation />
              </SubNavigation>
            )}
          </li>
        ) : null}
        {/* overrided to add a quick order link to the quick links section */}
        <li className="navigation__item">
          <Link buttonAppearance="icon" to="/quick-order">
            <Icon
              icon="lightning"
              title={intl.formatMessage(messages.quickOrder)}
            />
          </Link>
        </li>
        {!wishlistFeature.loading && wishlistFeature.isFeatureActive ? (
          <li className="navigation__item desktop-only">
            <Link buttonAppearance="icon" to="/user/wishlist">
              <Icon icon="heart" title={intl.formatMessage(messages.search)} />
            </Link>
          </li>
        ) : null}
        <li className="navigation__item">
          <Button
            onClick={() => selectMenu("cart")}
            appearance={"icon"}
            title={intl.formatMessage(messages.cart)}
          >
            <IconWithNotification
              icon="cart"
              title={intl.formatMessage(messages.cart)}
              notification={cartLength}
              notificationMessage={intl.formatMessage(messages.cartLength, {
                quantity: cartLength,
              })}
            />
          </Button>
          {renderSubNav("cart")(
            <SubNavigation appearance="aside" onClose={() => selectMenu(null)}>
              <CartNavigation />
            </SubNavigation>
          )}
        </li>
        <li className="navigation__item desktop-only">
          <AccountNavigation
            loading={userData?.loading ?? true}
            user={userData?.me}
            selectMenu={selectMenu}
            renderSubNav={renderSubNav}
          />
        </li>
        <li className="navigation__item mobile-only">
          <IconButton
            icon="menu"
            title={intl.formatMessage(messages.menu)}
            onClick={() => selectMenu("menu")}
          />
          {renderSubNav("menu")(
            <SubNavigation onClose={() => selectMenu(null)}>
              <MenuNavigationMobile
                data={data}
                loading={userData?.loading ?? true}
                user={userData?.me}
                wishlistFeature={wishlistFeature}
              />
            </SubNavigation>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default compose(
  withIsFeatureActive("searchFeature", "search"),
  withIsFeatureActive("wishlistFeature", "wishlist")
)(Navigation);
