import React from "react";
import CookieLink from "theme/modules/Cookie/CookieLink";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";

const Legislation = () => {
  return (
    <div className="footer-legislation">
      <ul className="footer-legislation__list container">
        <li className="footer-legislation__item">
          <CookieLink />
        </li>
        <li className="footer-legislation__item">
          <FormattedMessage
            id="layouts.Footer.Legislation.copyright"
            defaultMessage="© {year} All rights reserved"
            values={{ year: new Date().getFullYear() }}
          />
        </li>
        <li className="footer-legislation__item">
          <DevContributionMode enable={true} title="Enable contribution mode">
            ✔️
          </DevContributionMode>{" "}
          <DevContributionMode enable={false} title="Disable contribution mode">
            ⏹
          </DevContributionMode>
        </li>
      </ul>
    </div>
  );
};

const DevContributionMode = ({ enable = false, title, children }) => {
  const location = useLocation();
  // Use <a> to by-pass client-side routing
  return (
    <a
      href={`/contribution-mode/?enable=${enable}&returnTo=${location.pathname}`}
      className="link button button--ghost button--mini"
      title={title}
      style={{
        width: 35,
        height: 35,
        borderStyle: "dashed",
        textColor: "gray",
      }}
    >
      {children}
    </a>
  );
};

export default Legislation;
